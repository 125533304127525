
const resolvePrequelHostEnv = () => {
  const host = window.location.host;

  if (host.includes("localhost") || host.includes("staging")) {
    return 'staging';
  }

  if (host.includes("sandbox.eu.zuora") || host.includes("test.eu.zuora")) {
    return 'eu_sandbox'
  }

  //   US-SBX/NA-SBX/CSBX (All 3 served by prequel's US SBX deployment)
  //   NOTE: The below statement handles the 3 deployment - US-SBX/NA-SBX/US-CSBX
  if (host.includes("apisandbox.zuora") || host.includes("test.zuora") || host.includes("sandbox.na.zuora")) {
    return 'sandbox'
  }

  //   ---------- PROD ----------

  // TDOO: Return the EU PROD prequel deployment
  if (host.includes("eu.zuora")) {
    return 'eu_production'
  }

  //   TDOO: Return the NA PROD and US PROD prequel deployment
  if (host.includes("zuora")) {
    return 'production'
  }

  return 'staging'
}

const PREQUEL_API_HOST_ENV = resolvePrequelHostEnv();

const ENV_TO_PREQUEL_API_HOST_MAPPING = {
  'staging': 'https://prequel-stg-na.zuora.com',
  'eu_sandbox': 'https://prequel-sbx-eu.zuora.com',
  'sandbox': 'https://prequel-sbx-na.zuora.com',
  'eu_production': 'https://prequel-eu.zuora.com',
  'production': 'https://prequel-na.zuora.com',
};

export const PREQUEL_API_HOST = ENV_TO_PREQUEL_API_HOST_MAPPING[PREQUEL_API_HOST_ENV]

// Api urls
export const AUTH_TOKEN_URL = '/zdq/token'
export const FETCH_RECIPIENT_URL = '/zdq/recipient'


const PREQUEL_ORG_ID_TO_HOST_MAPPING = {
  'staging': '30f0d1f0-04cb-4aa4-a35a-d757c336f90e',
  'eu_sandbox': 'b95c991b-5c9d-4833-a8db-4f2f58050146',
  'sandbox': '2f48afa1-7f73-4ab7-9360-27f4a51b6134',
  'eu_production': 'ac301251-bd97-4bfc-981e-dee14bd9414a',
  'production': 'f2f155f9-6b66-4ac5-b8a7-5a34a6329884',
}

export const ORG_ID = PREQUEL_ORG_ID_TO_HOST_MAPPING[PREQUEL_API_HOST_ENV];

// Vendors
export const REDSHIFT_VENDOR = 'redshift';
export const BIGQUERY_VENDOR = 'bigquery';
export const S3_VENDOR = 's3';

// Interfaces
export interface BillingModelMetaData {
  objectName: string;
  id: number
}

export interface ConnectorT9Permissions {
  redshift: string;
  bigquery: string;
  s3: string;
}

// vendor to title mappings since some title might have a different formatting

export const VENDOR_TO_CONNECTOR_TITLE_MAPPINGS = {
  redshift: "Redshift",
  bigquery: "BigQuery",
  s3: "S3"
}

// T9 permissions mappings
export const T9_PERMISSION_MAPPINGS: ConnectorT9Permissions = { redshift: "ZuoraAWSRedshiftConnector", bigquery: "ZuoraBigQueryConnector", s3: "ZuoraAWSS3Connector" }

// Vendor Mapping for links to KC Docs
export const VENDOR_TO_KC_LINK_MAPPINGS = {
  redshift: { title: `AWS ${VENDOR_TO_CONNECTOR_TITLE_MAPPINGS.redshift}`, link: "https://knowledgecenter.zuora.com/Zuora_Central_Platform/Zuora_Connectors_for_Data_Warehouses/Zuora_Connector_for_AWS_Redshift" },
  bigquery: { title: `${VENDOR_TO_CONNECTOR_TITLE_MAPPINGS.bigquery}`, link: "https://knowledgecenter.zuora.com/Zuora_Central_Platform/Zuora_Connectors_for_Data_Warehouses/Zuora_Connector_for_BigQuery" },
  s3: { title: `AWS ${VENDOR_TO_CONNECTOR_TITLE_MAPPINGS.s3}`, link: "https://knowledgecenter.zuora.com/Zuora_Central_Platform/Zuora_Connectors_for_Data_Warehouses/Zuora_Connector_for_AWS_S3" },
}