import { Box, FormGroup } from "@mui/material";
import { Button, Card, Checkbox, FormLabel, Grid, Modal, ModalTitle, Typography, designTokens } from "@platform-ui/design-system";
import React, { ReactNode, useState } from "react"
import { Control, Controller, FieldErrors, FieldValues } from "react-hook-form";
import { DataGrid, GridRowSelectionModel, GridToolbar } from '@mui/x-data-grid';
import { Destination } from "@prequel/react";
import { BillingModelMetaData } from "../Constants";

interface BillingModelPropData {
    control: Control<FieldValues, any>,
    rows: BillingModelMetaData[],
    errors: FieldErrors<FieldValues>,
    setDestinationField: (key: keyof Destination, value: string | string[] | boolean | undefined) => void;
    register: (name: keyof Destination, options?: any) => any;
}

function SelectModels({ control, rows, errors, setDestinationField, register }: BillingModelPropData) {
    const [allCurrentAndFutureModelsSelected, setAllCurrentAndFutureModelsSelected] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const [prevRowSelection, setPrevRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 10,
        page: 0,
    });

    function resolveButtonName(): ReactNode {
        if (rowSelectionModel.length === 0) {
            return 'Select';
        }
        return 'Reselect';
    }

    const onClose = () => {
        setRowSelectionModel(prevRowSelection);
        setModalOpen(false)
    };

    return (
        <Controller
            name='connector-select-standard-objects'
            control={control}
            render={({ field: { onChange, value } }) => (
                <Box>
                    <Typography variant="title">Select Zuora Objects to receive Data</Typography>
                    <FormLabel required>Billing Standard Objects</FormLabel>

                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                        {rowSelectionModel?.length > 0 && <Typography sx={{ marginRight: '1%' }} variant="subtitle3" body={`${rowSelectionModel.length} of ${rows.length} objects selected`}></Typography>}
                        <Button sx={{ marginBottom: '1%' }}
                            body={resolveButtonName()}
                            dsOnClick={(e) => {
                                setModalOpen(true)
                                if (rowSelectionModel.length > 0) {
                                    setPrevRowSelectionModel(rowSelectionModel);
                                }
                            }}
                            variant={rowSelectionModel.length > 0 ? 'text' : 'contained'} />
                    </Box>
                    <Box>
                        {!allCurrentAndFutureModelsSelected && rowSelectionModel.length === 0 && <Typography variant="caption" color={designTokens.colors.red500} body={errors && errors['enabled_models'] && errors['enabled_models'].message ? errors['enabled_models'].message as string : ''} />}
                    </Box>
                    <Modal
                        {...register('enabled_models', {
                            validate: (value, formValues) => {
                                if (!allCurrentAndFutureModelsSelected && rowSelectionModel.length === 0) {
                                    return 'This is a required field'
                                }
                                return true;
                            }
                        })}
                        maxWidth="sm"
                        fullWidth
                        open={modalOpen}
                        id="connector-select-models"
                        dsOnClose={onClose}
                        header={<ModalTitle dsOnClose={onClose}>Select Billing Standard Objects</ModalTitle>}
                        body={
                            <DataGrid
                                disableRowSelectionOnClick
                                paginationModel={paginationModel}
                                isRowSelectable={() => !allCurrentAndFutureModelsSelected}
                                pageSizeOptions={[5, 10, 20]}
                                onPaginationModelChange={setPaginationModel}
                                sx={{
                                    '.MuiDataGrid-toolbarContainer': {
                                        display: 'block',
                                    },
                                    '.MuiTablePagination-selectLabel': {
                                        margin: '0px'
                                    },
                                    '.MuiTablePagination-displayedRows': {
                                        margin: '0px'
                                    },
                                    '.MuiDataGrid-filler': {
                                        display: 'none'
                                    }
                                }}
                                rows={rows}
                                disableColumnFilter
                                checkboxSelection
                                disableColumnSelector
                                disableDensitySelector
                                disableColumnMenu
                                slots={{ toolbar: GridToolbar }}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        csvOptions: { disableToolbarButton: true },
                                        printOptions: { disableToolbarButton: true }
                                    },
                                    filterPanel: {
                                        disableAddFilterButton: true
                                    }
                                }}
                                onRowSelectionModelChange={(newRowSelectionModel) => {
                                    setRowSelectionModel(newRowSelectionModel);
                                    setDestinationField('enabled_models', rows.filter((row) => newRowSelectionModel.includes(row.id)).map((row) => row['objectName']));
                                }}
                                rowSelectionModel={rowSelectionModel}
                                columns={[
                                    {
                                        field: 'id',
                                        headerName: 'Number',
                                    },
                                    {
                                        field: 'objectName',
                                        headerName: 'Object Name',
                                        sortable: true,
                                        width: 200,
                                    }
                                ]}
                            />
                        }
                        footer={
                            <Card id='select-future-objects' body={
                                <FormGroup>
                                    <Checkbox
                                        // disabled={!isSelectable}
                                        label={
                                            <Typography variant="title">Select all current and future objects</Typography>
                                        }
                                        dsOnChange={(e) => {
                                            setAllCurrentAndFutureModelsSelected(e.target.checked);
                                            if (e.target.checked) {
                                                setRowSelectionModel(Array.from({ length: rows.length }, (_, index) => index + 1));
                                                setDestinationField('enabled_models', ["*"])
                                            } else {
                                                setRowSelectionModel([])
                                            }
                                        }}
                                        checked={allCurrentAndFutureModelsSelected}
                                    />
                                    <Typography variant='caption' color={designTokens.colors.coolGray300}>Objects added in the future will be syned automatically</Typography>
                                    <Grid container direction="row" justify="flex-end">
                                        <Grid item>
                                            <Button
                                                dsOnClick={(e) => {
                                                    setRowSelectionModel(prevRowSelection);
                                                    setAllCurrentAndFutureModelsSelected(false);
                                                    setModalOpen(false);
                                                }}
                                                body='Cancel'
                                                variant='tertiary'
                                                size="medium"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                dsOnClick={(e) => {
                                                    setModalOpen(false);
                                                }}
                                                body='Done'
                                                size="medium"
                                            />
                                        </Grid>
                                    </Grid>
                                </FormGroup>
                            }>
                            </Card>
                        }
                    />
                </ Box >
            )}
        />
    );
}

export default SelectModels