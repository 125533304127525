import React, { Dispatch, useCallback, useState } from "react"
import { Box } from "@mui/material"
import { Alert, Button, Grid, Icon, Modal, ModalTitle, ToastController, Typography } from "@platform-ui/design-system"
import { PreparedDestination, useCreateDestination, useTestConnection } from "@prequel/react";
import Connect from "../../../../Connect/Connect";
import { SubmitHandler } from "react-hook-form";
import { Action, useStoreContext } from "../../../../Store";
import { IADState } from "../../../../IntegrationApps/IntegrationAppDetails/state";
import { AUTH_TOKEN_URL,  PREQUEL_API_HOST } from "../Constants";
import { ZDQ_DESTINATION_DATA } from "../action_types";

type TestConnectionProps = {
    handleSubmit: any;
    vendorTitle: string;
    preparedDestination: PreparedDestination;
    fetchTokenWithDestination: (url: string, preparedDestination: PreparedDestination) => Promise<string>;
    setMainModalOpen: (value: boolean) => void;
};

function TestConnection({ handleSubmit, vendorTitle, preparedDestination, fetchTokenWithDestination, setMainModalOpen }: TestConnectionProps) {
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [testConnectionPassed, setTestConnectionPassed] = useState(false);
    const [toast, setToast] = useState({
        show: false,
        msg: '',
        severity: '',
        duration: 0,
        loading: false
    });
    const { state, dispatch } = useStoreContext() as { state: IADState, dispatch: Dispatch<Action> };
    const fetchAuthTokenUrl = `${state.settingsUrl.replace('.json', AUTH_TOKEN_URL)}`;

    // TODO: Check if the same hook can be used for creating the destination
    const fetchAuthTokenWithDestinationWrapper = useCallback(async (destination: PreparedDestination) => {
        return await fetchTokenWithDestination(fetchAuthTokenUrl, destination);
    }, []);
    const testConnection = useTestConnection(
        fetchAuthTokenWithDestinationWrapper,
        window.location.host,
        PREQUEL_API_HOST
    );

    const createDestination = useCreateDestination(
        fetchAuthTokenWithDestinationWrapper,
        window.location.host,
        PREQUEL_API_HOST
    );

    async function handleSaveAndInstall() {
        setConfirmationModalOpen(false);
        setToast({ ...toast, duration: 100000, show: true, msg: 'Creating Destination...', severity: 'info', loading: true });
        try {
            const response = await createDestination(preparedDestination);
            if (response && response.status === 'success') {
                setMainModalOpen(false);
                setToast({ ...toast, show: false });
                dispatch({ type: ZDQ_DESTINATION_DATA, payload: response.data.destination })

            } else if (response && response.status === 'error') {
                setToast({ ...toast, show: true, duration: 10000, severity: 'error', msg: response.message || 'Encountered an error while trying to create the destination. Please try again' });
            }
            Connect.log(`Create Destination: ${response}`);
        } catch (e) {
            setToast({ ...toast, show: true, duration: 10000, severity: 'error', msg: 'Error while creating the destination' });
            Connect.log(e);
        }
    }

    const onSubmit: SubmitHandler<any> = (data) => {
        testDestinationConnection();
    };
    async function testDestinationConnection() {
        setToast({ ...toast, duration: 100000, show: true, msg: 'Testing Connection...', severity: 'info', loading: true });
        try {
            const res = await testConnection(preparedDestination);
            if (res && res.status === 'success') {
                setToast({ ...toast, show: true, duration: 4000, severity: 'success', msg: 'Connection test passed. Destination is ready to be saved.' });
                setTestConnectionPassed(true);
            } else if (res && res.status === 'error') {
                setToast({ ...toast, show: true, duration: 10000, severity: 'error', msg: res.message });
            }
        } catch (e) {
            Connect.log("Error while testing connection", e)
        }
    }

    return (
        <>
            <Box sx={{ marginTop: 2 }}>
                {toast.show && <ToastController closeable loading={toast.loading} duration={toast.duration} severity={toast.severity as any} message={toast.msg} />}
                <Alert
                    header="Test the connection before saving"
                    open
                    severity="info"
                >
                    <div>The save function will remain inactive until the connection test is completed and passed</div>
                </Alert>
            </Box>
            <Grid sx={{ marginTop: 5 }} container direction='row' justify="flex-end">
                <Grid item>
                    <Button
                        dsOnClick={handleSubmit(onSubmit)}
                        variant="outlined"
                    >
                        Test Connection
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        dsOnClick={handleSubmit(setConfirmationModalOpen)}
                        disabled={!testConnectionPassed}
                    >
                        Save and Install
                    </Button>
                </Grid>
            </Grid>

            <Modal maxWidth="sm"
                id="confirmation-modal" open={confirmationModalOpen} dsOnClose={() => { setConfirmationModalOpen(false) }}
                header={
                    <ModalTitle icon={<Icon body='info' variant="filled" color="info" ></Icon >}
                        dsOnClose={() => { setConfirmationModalOpen(false) }} body={`Save and Install your ${vendorTitle} Connection`} />
                }
                body={
                    <>
                        <Typography variant="body1"
                            body={
                                <>
                                    Clicking 'Save & Install' begins the connector provisioning process,
                                    which typically takes between 7 to 21 days.
                                    <br />
                                    <br />
                                    You can track the progress on the Authentication Setup page. Once the status changes to 'Active', it indicates that the connection has been successfully established. Please be aware that this action cannot be undone via the UI. If you need to revert it, please reach out to a Zuora representative.
                                    <br />
                                    <br />
                                    Kindly confirm if you would like to proceed with this action.
                                </>
                            }>
                        </Typography >
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '2%' }}>
                            <Button
                                dsOnClick={() => {
                                    setConfirmationModalOpen(false)
                                }}
                                variant="outlined"
                            >
                                Cancel
                            </Button>
                            <Button sx={{ marginLeft: '2%' }}
                                dsOnClick={handleSaveAndInstall}
                            >
                                Save & Install
                            </Button>
                        </Box>
                    </>
                }
            />
        </>
    )

}

export default TestConnection