import React, { Fragment, useMemo, useState } from "react"
import { Box, FormControl } from '@mui/material';
import { Avatar, Button, Card, FormHelperText, Grid, Icon, Link, Modal, ModalTitle, Radio, RadioGroup, Select, SelectItem, TextField, Typography, designTokens } from "@platform-ui/design-system";
import SelectModels from "./SelectModels";
import ConstField from "./ConstField";
import TestConnection from "./TestConnection";
import { Controller, useForm } from "react-hook-form";
import BooleanChiclets from "./BooleanChiclets";
import { Destination, Form, prepareDestinationWithForm } from "@prequel/react";
import { BillingModelMetaData } from "../Constants";
import fetchAuthTokenWithDestination from "../hooks/fetchAuthTokenWithDestination";
import styled from '@emotion/styled';

const StyledConnectorNotConfiguredIcon = styled.div`
         > span {
            font-size : xxx-large
         }; 
        `;

const StyledErrorContainer = styled.div`
    > div > div:last-child {
        padding-bottom: 0;
    }; 
    `;

interface ConnectorAuthenticationProps {
    title: string,
    models: BillingModelMetaData[];
    destination: Destination
    destinationForm: Form
    vendorData: {title: string, link: string}
    setDestinationField: (key: keyof Destination, value: string | string[] | boolean | undefined) => void;
}

function ConnectorAuthentication({ title, models, destination, destinationForm, vendorData, setDestinationField }: ConnectorAuthenticationProps) {

    const [modalOpen, setModalOpen] = useState(false);
    const preparedDestination = useMemo(
        () => prepareDestinationWithForm(destination, destinationForm),
        [destination, destinationForm]
    );

    const {
        control,
        handleSubmit,
        getFieldState,
        register,
        formState: { errors },
    } = useForm();

    return (
        <>
            <Card autoDistance
                id="connector-details"
                space="large"
                noPaddingBottom
                header={
                    <>
                        <Typography variant="h5" body={`${title} Connection`} />
                        {/* <Grid container>
                            <Grid item><ConnectorMetaInfo metaDataTitle='Last Updated By' metaData='zuora@zuora.com' showBarAtEnd={true}></ConnectorMetaInfo></Grid>
                            <Grid item><Box sx={{ display: 'flex' }}><Typography variant='label' color='gray' body='|' /></Box></Grid>
                            <Grid item><ConnectorMetaInfo metaDataTitle='Last Updated Date' metaData={savedDestinationData?.created_at} showBarAtEnd={false}></ConnectorMetaInfo></Grid>
                        </Grid> */}
                    </>
                }
                titleBar
                body={
                    <>
                        <Typography color={designTokens.colors.coolGray300} variant='label' body={
                            <>
                                If you are not sure how to get started or want to read about our recommended configuration, view our documentation on
                                <Link href={vendorData.link}
                                    target='_blank' underline='hover'
                                    color="primary"> Zuora Connector for {vendorData.title}.
                                </Link>
                            </>
                        } />
                        <Grid sx={{ marginTop: '3%', marginBottom: '3%' }} container direction="column" alignItems="center">
                            <Grid item>
                                <StyledConnectorNotConfiguredIcon>
                                    <Icon body='database_off' variant="outlined" fontSize="large" color="disabled"></Icon>
                                </StyledConnectorNotConfiguredIcon>
                            </Grid>
                            <Grid item>
                                <Typography color={designTokens.colors.coolGray400} variant='h6' body="You haven't configured this connector" />
                            </Grid>
                            <Grid item>
                                <Button dsOnClick={() => { setModalOpen((prev) => { return !prev }) }} startIcon='edit'>
                                    Configure
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                }>
            </Card >
            <Modal
                id='connector-authentication-setup-form' open={modalOpen} fullWidth dsOnClose={() => { setModalOpen(false) }}
                header={
                    <Box sx={{ '.MuiAvatar-root': { backgroundColor: designTokens.colors.coolGray100 } }}>
                        <ModalTitle icon={<Avatar label=""><Icon body='cable' fontSize="large" /></Avatar>}
                            dsOnClose={() => { setModalOpen(false) }} body={`Configure your ${title} connection`} />
                    </Box >
                }
                body={
                    <>
                        {
                            destinationForm?.map((section, sectionIndex) => (
                                <Fragment key={`${section.id}_${sectionIndex}`}>
                                    <Box key={`$Box_{section.id}_${sectionIndex}`} sx={{ '> div': { minWidth: '100%' } }}>
                                        <FormControl>
                                            {section.id > 2 &&
                                                <Box sx={{ borderBottom: '1px solid lightgray', paddingBottom: 3, marginBottom: 3 }}>
                                                    <Box sx={{ marginBottom: '0.5%' }}>
                                                        <Typography variant="title">{section.title}</Typography>
                                                        <FormHelperText>{section.subtitle}</FormHelperText>
                                                    </Box>
                                                    {section?.fields?.map((field, fieldIndex) => {
                                                        if (field.const) {
                                                            return (<Box key={`connector-const-field-${fieldIndex}`} sx={{ margin: '1%', marginLeft: '0' }}><ConstField showDescription={true} field={field} /></Box>);
                                                        } else if (field.form_element === "radio") {
                                                            if (field.type === "boolean") {
                                                                return (
                                                                    <Box key={`connector-boolean-field-${fieldIndex}`} sx={{ marginBottom: 1.5 }}>
                                                                        <Controller
                                                                            name={field.name}
                                                                            control={control}
                                                                            render={({ field: { onChange, value } }) => (
                                                                                <RadioGroup
                                                                                    key={field.name}
                                                                                    required={field.required}
                                                                                    label={field.label}
                                                                                    value={destination[field.name]}
                                                                                    dsOnChange={(e) => {
                                                                                        setDestinationField(field.name, e.target.value === 'false' ? false : true)
                                                                                        onChange(e);
                                                                                    }}
                                                                                    error={!!getFieldState(field.name).error}
                                                                                >
                                                                                    <Grid container direction='row'>
                                                                                        {field?.enum?.map((item) => (
                                                                                            <Grid item>
                                                                                                <Radio
                                                                                                    key={item.key as string}
                                                                                                    checked={!!destination[field.name] === item.key}
                                                                                                    value={item.key}
                                                                                                    label={item.display}
                                                                                                />
                                                                                            </Grid>
                                                                                        ))}
                                                                                    </Grid>
                                                                                    {!!getFieldState(field.name).error &&
                                                                                        <StyledErrorContainer>
                                                                                            <FormHelperText label={errors[field.name]['message'] as string}></FormHelperText>
                                                                                        </StyledErrorContainer>
                                                                                    }
                                                                                    {field.description && <FormHelperText label={field.description}></FormHelperText>}
                                                                                </RadioGroup>
                                                                            )}
                                                                        />
                                                                    </Box>

                                                                );
                                                            }
                                                            return (
                                                                <Box key={`connector-non-boolean-field-${fieldIndex}`} sx={{ marginBottom: 1.5 }}>
                                                                    <BooleanChiclets field={field} control={control} destination={destination} errors={errors} setDestinationField={setDestinationField} register={register} />
                                                                </Box>
                                                            )
                                                        } else if (field.form_element === "select") {
                                                            const items = field.enum?.map(({ key, display, icon_url }) => ({
                                                                key: key.toString(),
                                                                display,
                                                                icon_url
                                                            }));
                                                            const selected = items?.find(
                                                                ({ key }) => key.toString() === destination[field.name]
                                                            );
                                                            return (
                                                                <Box key={`connector-select-field-${fieldIndex}`} sx={{ marginBottom: 1.5 }}>
                                                                    <Controller
                                                                        name={field.name}
                                                                        control={control}
                                                                        rules={{ required: true }}
                                                                        render={({ field: { onChange, value } }) => (
                                                                            <Select
                                                                                key={field.name}
                                                                                value={selected?.key}
                                                                                label={field.label}
                                                                                dsOnChange={(event) => {
                                                                                    setDestinationField(field.name, event.target.value as string)
                                                                                    onChange(event)
                                                                                }
                                                                                }
                                                                                required={field.required}
                                                                                helperText={!!getFieldState(field.name).error ? errors[field.name]['message'] as string : ''}
                                                                                error={!!getFieldState(field.name).error}
                                                                            >
                                                                                {items?.map((item, index) => (
                                                                                    <SelectItem key={item.key as string} value={item.key}>
                                                                                        {item.display}
                                                                                    </SelectItem>
                                                                                ))}
                                                                            </Select>
                                                                        )}
                                                                    />
                                                                    {field.description && <FormHelperText label={field.description}></FormHelperText>}
                                                                </Box>
                                                            );
                                                        } else if (field.form_element === "textarea") {
                                                            return (
                                                                <Box key={`connector-textarea-field-${fieldIndex}`} sx={{ marginBottom: 1.5 }}>
                                                                    <Controller
                                                                        name={field.name}
                                                                        control={control}
                                                                        render={({ field: { onChange, value } }) => (
                                                                            <>
                                                                                <TextField
                                                                                    key={field.name}
                                                                                    {...register(field.name, {
                                                                                        required: { value: field.required, message: `${field.label || 'This'} is a required field.` }
                                                                                    })}
                                                                                    multiline
                                                                                    rows={2}
                                                                                    fullWidth
                                                                                    dsOnChange={(event) => {
                                                                                        setDestinationField(field.name, event.target.value);
                                                                                        onChange(event);
                                                                                    }}
                                                                                    label={field.label}
                                                                                    value={destination[field.name] as string || ''}
                                                                                    placeholder={field.placeholder || '••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••'}
                                                                                    type='text'
                                                                                    required={field.required}
                                                                                    error={!!getFieldState(field.name).error}
                                                                                    helperText={!!getFieldState(field.name).error ? errors[field.name]['message'] as string : ''}
                                                                                />
                                                                                {field.description && <FormHelperText label={field.description}></FormHelperText>}
                                                                            </>
                                                                        )}
                                                                    />
                                                                </Box>
                                                            );
                                                        } else if (field.form_element === "input") {
                                                            return (
                                                                <Box key={`connector-input-field-${fieldIndex}`} sx={{ marginBottom: 1.5 }}>
                                                                    <Controller
                                                                        name={field.name}
                                                                        control={control}
                                                                        // rules={{ required: true }}
                                                                        render={({ field: { onChange, value } }) => (
                                                                            <>
                                                                                <TextField
                                                                                    key={field.name}
                                                                                    {...register(field.name, {
                                                                                        required: { value: field.required, message: `${field.label || 'This'} is a required field.` }
                                                                                    })}
                                                                                    fullWidth
                                                                                    dsOnChange={(event) => {
                                                                                        setDestinationField(field.name, event.target.value);
                                                                                        onChange(event);
                                                                                    }}
                                                                                    label={field.label}
                                                                                    value={destination[field.name] as string}
                                                                                    placeholder={field.placeholder}
                                                                                    type='text'
                                                                                    required={field.required}
                                                                                    error={!!getFieldState(field.name).error}
                                                                                    helperText={!!getFieldState(field.name).error ? errors[field.name]['message'] as string : ''}
                                                                                />
                                                                                {field.description && <FormHelperText label={field.description}></FormHelperText>}
                                                                            </>
                                                                        )}
                                                                    />
                                                                </Box>
                                                            );
                                                        }
                                                    })}
                                                </Box>
                                            }
                                        </FormControl>
                                    </Box>
                                </Fragment>
                            ))
                        }
                        {models && models.length > 0 && <SelectModels errors={errors} register={register} setDestinationField={setDestinationField} rows={models} control={control}></SelectModels>}
                        <TestConnection setMainModalOpen={setModalOpen} fetchTokenWithDestination={fetchAuthTokenWithDestination} vendorTitle={title} handleSubmit={handleSubmit} preparedDestination={preparedDestination} />
                    </>
                }
            />
        </>
    )
}

export default ConnectorAuthentication 