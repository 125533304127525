import Connect from "../../../../Connect/Connect";

const fetchAuthTokenWithRecipientId: (url: string, i?: string) => Promise<string> = async (url, recipient_id) => {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute("content")
      },
      body: JSON.stringify({ recipient_id: recipient_id }),
    });
    const body = await response.json();
    return body.scoped_token;
  } catch (e) {
    Connect.log(e);
    console.error(e);
  }
}

export default fetchAuthTokenWithRecipientId;