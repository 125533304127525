import React, { Dispatch, useCallback, useEffect, useState } from "react"
import { Action, useStoreContext } from "../../../../Store";
import { IADState } from "../../../../IntegrationApps/IntegrationAppDetails/state";
import { useIdentity } from "../../../../IdentityProvider";
import { Destination, useDestination, useDestinationForm, useGetDestinationsForRecipient, useProducts } from "@prequel/react";
import { AUTH_TOKEN_URL, FETCH_RECIPIENT_URL, ORG_ID, PREQUEL_API_HOST, T9_PERMISSION_MAPPINGS, VENDOR_TO_CONNECTOR_TITLE_MAPPINGS, VENDOR_TO_KC_LINK_MAPPINGS } from "../Constants";
import Connect from "../../../../Connect/Connect";
import DestinationDetails from "./DestinationDetails";
import { Card, Spinner, Typography, designTokens } from "@platform-ui/design-system";
import { IdentityPermissions } from "../../../../IdentityProvider/constants/permissions";
import { ZDQ_DESTINATION_DATA, ZDQ_RECIPIENT } from "../action_types";
import fetchAuthTokenWithRecipientId from "../hooks/fetchAuthTokenWithRecipientId";
import fetchAuthToken from "../hooks/fetchAuthToken";
import ConnectorAuthentication from "./ConnectorAuthentication";
import { BillingModelMetaData } from "../Constants";
import _ from 'lodash';

interface Recipient {
    id: string;
    name: string;
    schema: string;
    id_in_provider_system: string;
    products: string[];
    is_deleted: boolean;
    created_at: string;
    updated_at: string;
  }

function ZdqConnectorSetup({ vendor }) {

    // Contexts
    // const { recipient, setRecipient, destinationData, setDestinationData } = useConnectorContext();
    const { state, dispatch } = useStoreContext() as { state: IADState, dispatch: Dispatch<Action> };

    // T9 permissions
    const { hasPermission } = useIdentity();
    const hasPermissionsEnabled = hasPermission(`permission.${T9_PERMISSION_MAPPINGS[vendor]}` as IdentityPermissions)

    // hooks
    const [destination, setDestination] = useDestination();
    const destinationForm = useDestinationForm(destination, ORG_ID, false, PREQUEL_API_HOST);

    // Vars
    const fetchTokenUrl = state.settingsUrl.replace('.json', AUTH_TOKEN_URL);

    // States
    const [showSpinner, setShowSpinner] = useState(false);

    const destinationData = state.settingsHash[ZDQ_DESTINATION_DATA];
    const recipient = state.settingsHash[ZDQ_RECIPIENT];

    async function getRecipient() {
        try {
            const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
            const options = {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-CSRF-Token': csrf
                })
            };
            const response = await window.fetch(`${state.settingsUrl.replace('.json', FETCH_RECIPIENT_URL)}`, options);
            const data: { recipients: Recipient[], messsage?: string } = await response.json();
            if (!response.ok) {
                throw Error(data.messsage || 'Error while fetching the recipient for the tenant!');
            }
            if (data && data.recipients && data.recipients.length === 1) {
                dispatch({ type: ZDQ_RECIPIENT, payload: data.recipients[0] })
                return data.recipients[0];
            }
            Connect.log('Multiple/Zero recipients returned for the tenant', response);
        } catch (error) {
            Connect.log(error);
        }
    }

    const fetchAuthTokenWithRecipientIdWrapper = useCallback(async (recipientId: string) => {
        return await fetchAuthTokenWithRecipientId(fetchTokenUrl, recipientId);
    }, []);
    const destinationDetails = useGetDestinationsForRecipient(fetchAuthTokenWithRecipientIdWrapper, window.location.host, PREQUEL_API_HOST);
    async function getDestinationDetails() {
        try {
            setShowSpinner(true);
            const rec = await getRecipient();
            if (!rec) {
                throw Error('Error while fetching destination. No recipient found');
            }
            const response = await destinationDetails(rec?.id);
            if (response && response.status === 'error') {
                throw Error(response['message'] || 'Error while fetching destination for the tenant!');
            }
            if (response && response.status === 'success') {
                const existDest = response.data?.destinations?.filter((dest) => dest?.id_in_provider_system === rec?.id_in_provider_system && dest.vendor === vendor)[0] || undefined
                dispatch({ type: ZDQ_DESTINATION_DATA, payload: existDest })
                return;
            }
            Connect.log('Response status is neither success nor error while fetching destination.', response);
        } catch (error) {
            dispatch({ type: ZDQ_DESTINATION_DATA, payload: {} })
            Connect.log(error);
        } finally {
            setShowSpinner(false);
        }
    }

    useEffect(() => {
        if (localStorage.getItem("bypassPermissions") || hasPermissionsEnabled)
        getDestinationDetails();
    }, []);

    // Products and Models stuff
    const fetchAuthTokenWrapper = useCallback(async () => {
        return await fetchAuthToken(fetchTokenUrl);
    }, []);
    const products = useProducts(fetchAuthTokenWrapper, window.location.host, PREQUEL_API_HOST)?.filter((product) => recipient?.products?.includes(product.product_name));
    Connect.log('Zdq Connector setup products = ', products);
    const models = products && products.length === 1 ? products[0].models?.map((model, index) => {
        return {
            objectName: model,
            id: index + 1
        } as BillingModelMetaData
    }) : [];
    Connect.log('Zdq Connector setup models = ', models);

    const setDestinationField = useCallback(
        (
            key: keyof Destination,
            value: string | string[] | boolean | undefined
        ) => {
            setDestination((currentDestination) => ({
                ...currentDestination,
                [key]: value,
            }));
        },
        [setDestination]
    );

    useEffect(() => {
        setDestinationField('vendor', vendor);
        setDestinationField('recipient_id', recipient?.id);
        setDestination((currentDestination) => ({
            ...currentDestination,
            name: `ZDQ_${recipient?.id_in_provider_system}_${vendor}_connector`,
            id_in_provider_system: recipient?.id_in_provider_system,
            products: recipient?.products
        }));
    }, [setDestination, recipient])
    
    // TODO: Temporarily added this flag to bypass permissions. To be removed once things are stable
    if (!localStorage.getItem('bypassPermissions') && !hasPermissionsEnabled) {
        return <Card autoDistance id="disabled-tenant-card" body={
            <Typography color={designTokens.colors.coolGray300}
                sx={{ marginTop: '5%', marginBottom: '5%', textAlign: 'center' }}
                variant="body1"
                body="You don't have permissions enabled to view this tab. Please contact Zuora support if you are seeing this message." />}
        />
    }

    if (showSpinner || destinationData === undefined) {
        return <Spinner e2e="connector-auth-setup-spinner" size="large"></Spinner>
    }

    if (!recipient || !models) {
        return <Card autoDistance id="connector-setup-no-recipient-or-models" body={
            <Typography color={designTokens.colors.coolGray300}
                sx={{ marginTop: '5%', marginBottom: '5%', textAlign: 'center' }}
                variant="body1"
                body="Error while fetching the recipient details for this tenant. Please contact Zuora support if you are seeing this message." />
        }
        />
    }

    if (!_.isEmpty(destinationData) && recipient && models && destinationForm) {
        return <DestinationDetails title={vendor} destinationData={destinationData} destinationForm={destinationForm} models={models}></DestinationDetails>
    }

    return <ConnectorAuthentication title={VENDOR_TO_CONNECTOR_TITLE_MAPPINGS[vendor]} models={models} destination={destination} destinationForm={destinationForm} vendorData={VENDOR_TO_KC_LINK_MAPPINGS[vendor]} setDestinationField={setDestinationField}></ConnectorAuthentication>
}

export default ZdqConnectorSetup 